import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import HeaderDisplayButton from '../../views/query/DisplayButtons/HeaderDisplayButton';
import { displayHeader, hideHeader } from '../../store/uiSlice';

// styles
const Main = styled('main', {
    name: 'Main',
    overridesResolver: (props, styles) => {
        return [styles.root];
    }
})({});

const ThemedAppBar = styled(AppBar, {
    name: 'AppBar',
    overridesResolver: (props, styles) => {
        return [styles.root];
    }
})({});
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//     ...theme.typography.mainContent
// ...(!open && {
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//     transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen
//     }),
//     [theme.breakpoints.up('md')]: {
//         marginLeft: -(drawerWidth - 20),
//         width: `calc(100% - ${drawerWidth}px)`
//     },
//     [theme.breakpoints.down('md')]: {
//         marginLeft: '20px',
//         width: `calc(100% - ${drawerWidth}px)`,
//         padding: '16px'
//     },
//     [theme.breakpoints.down('sm')]: {
//         marginLeft: '10px',
//         width: `calc(100% - ${drawerWidth}px)`,
//         padding: '16px',
//         marginRight: '10px'
//     }
// }),
// ...(open && {
//     transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen
//     }),
//     marginLeft: 0,
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//     width: `calc(100% - ${drawerWidth}px)`,
//     [theme.breakpoints.down('md')]: {
//         marginLeft: '20px'
//     },
//     [theme.breakpoints.down('sm')]: {
//         marginLeft: '10px'
//     }
// })
// }));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const headerDisplaying = useSelector((state) => state.ui.layout.headerDisplaying);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    // Function to update window size
    const updateWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    // Add event listener when the component mounts
    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    // console.log(windowSize.width);
    // const dispatch = useDispatch();
    //
    // if (windowSize.width < 600) {
    //     dispatch(hideHeader());
    // } else {
    //     dispatch(displayHeader());
    // }

    return (
        <Box sx={{ display: 'flex' }}>
            {/*<CssBaseline />*/}
            {/* header */}
            <ThemedAppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={2}
                // sx={{
                //     bgcolor: theme.palette.background.default
                // }}
            >
                <Toolbar>
                    {windowSize.width > 900 && <Header />}
                    {windowSize.width <= 900 && <HeaderDisplayButton />}
                </Toolbar>
            </ThemedAppBar>

            {/* main content */}
            <Main>
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
