import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { QueryStatus } from '../../assets/constant';
import { flaskBackendAxiosInstance } from '../../axios';

const filterOutEdgesWithNoEndpoints = (edges, nodes) => {
    const nodesIdSet = new Set(nodes.forEach((node) => node.node_id));
    return edges.filter((edge) => nodesIdSet.has(edge.start_node) && nodesIdSet.has(edge.end_node));
    // return edges;
};

// ==============================|| RESULT SLICE ||============================== //

export const fetchResultWithGraphicalQuery = createAsyncThunk('/query/graphical', async (graphicalObject) => {
    return await flaskBackendAxiosInstance
        .post('/query', graphicalObject, {
            mode: 'cors'
        })
        .then((response) => {
            return response.data;
        })
        .catch((response) => {
            console.log('test here, ', response);
        });
});

const resultNodeAdapter = createEntityAdapter({ sortComparer: (a, b) => (a.id > b.id ? 1 : -1) });

const resultNodeInitialState = resultNodeAdapter.getInitialState({});

const resultEdgeAdapter = createEntityAdapter({ sortComparer: (a, b) => (a.id > b.id ? 1 : -1) });

const resultEdgeInitialState = resultEdgeAdapter.getInitialState({});

const associatedQueryNodeAdapter = createEntityAdapter({ sortComparer: (a, b) => (a.id > b.id ? 1 : -1) });

const associatedQueryNodeInitialState = associatedQueryNodeAdapter.getInitialState({});

const associatedQueryEdgeAdapter = createEntityAdapter({ sortComparer: (a, b) => (a.id > b.id ? 1 : -1) });

const associatedQueryEdgeInitialState = associatedQueryEdgeAdapter.getInitialState({});

export const resultSlice = createSlice({
    name: 'result',
    initialState: {
        nodes: resultNodeInitialState,
        edges: resultEdgeInitialState,
        resultQueryStatus: QueryStatus.IDLE,
        resultQueryStatusMessage: '',
        associatedQueryNodes: associatedQueryNodeInitialState,
        associatedQueryEdges: associatedQueryEdgeInitialState
    },
    reducers: {
        setResultNodeList: (state, action) => {
            resultNodeAdapter.setAll(state.nodes, action.payload);
        },
        setResultLinkList: (state, action) => {
            resultEdgeAdapter.setAll(state.edges, action.payload);
        },
        setAssociatedNodeList: (state, action) => {
            associatedQueryNodeAdapter.setAll(state.associatedQueryNodes, action.payload);
        },
        setAssociatedEdgeList: (state, action) => {
            associatedQueryEdgeAdapter.setAll(state.associatedQueryEdges, action.payload);
        },
        reinitializeResultSlice: (state, action) => {
            resultNodeAdapter.removeAll(state.nodes);
            resultEdgeAdapter.removeAll(state.edges);
            state.resultQueryStatus = QueryStatus.IDLE;
            associatedQueryNodeAdapter.removeAll(state.associatedQueryNodes);
            associatedQueryEdgeAdapter.removeAll(state.associatedQueryEdges);
        },
        resetResultQueryStatus: (state, action) => {
            state.resultQueryStatus = QueryStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchResultWithGraphicalQuery.pending, (state, action) => {
                state.resultQueryStatus = QueryStatus.LOADING;
            })
            .addCase(fetchResultWithGraphicalQuery.fulfilled, (state, action) => {
                console.log('action payload', action);
                if (action.payload.hasOwnProperty('error')) {
                    state.resultQueryStatus = QueryStatus.FAILED;
                    state.resultQueryStatusMessage = action.payload.error;
                } else {
                    const { n: nodes, e: edges } = action.payload;
                    resultNodeAdapter.setAll(state.nodes, nodes);
                    resultEdgeAdapter.setAll(state.edges, edges);
                    state.resultQueryStatus = QueryStatus.SUCCESS;
                    state.resultQueryStatusMessage = '';
                }
            })
            .addCase(fetchResultWithGraphicalQuery.rejected, (state, action) => {
                state.resultQueryStatus = QueryStatus.FAILED;
                console.log('extra reducer error in result slice -> ', action.error);
            });
    }
});

export const {
    setResultNodeList,
    setResultLinkList,
    reinitializeResultSlice,
    resetResultQueryStatus,
    setAssociatedNodeList,
    setAssociatedEdgeList
} = resultSlice.actions;

export default resultSlice.reducer;

export const { selectAll: selectAllResultNodes, selectById: selectResultNodeById } = resultNodeAdapter.getSelectors(
    (state) => state.result.nodes
);

export const { selectAll: selectAllResultEdges, selectById: selectResultEdgeById } = resultEdgeAdapter.getSelectors(
    (state) => state.result.edges
);

export const { selectAll: selectAllAssociatedQueryNodes, selectById: selectAssociatedQueryNodesById } =
    associatedQueryNodeAdapter.getSelectors((state) => state.result.associatedQueryNodes);

export const { selectAll: selectAllAssociatedQueryEdge, selectById: selectAssociatedQueryEdgeById } =
    associatedQueryEdgeAdapter.getSelectors((state) => state.result.associatedQueryEdges);
