import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QueryStatus } from '../../assets/constant';
import { flaskBackendAxiosInstance } from '../../axios';

export const exportToEmail = createAsyncThunk('/email', async ({ email, graphObj }) => {
    return await flaskBackendAxiosInstance
        .post('/email', graphObj, {
            mode: 'cors',
            params: { email }
        })
        .then((response) => response.data);
});

export const exportSlice = createSlice({
    name: 'export',
    initialState: {
        exportStatus: QueryStatus.IDLE
    },
    extraReducers: (builder) => {
        builder
            .addCase(exportToEmail.pending, (state) => {
                state.exportStatus = QueryStatus.LOADING;
            })
            .addCase(exportToEmail.fulfilled, (state) => {
                state.exportStatus = QueryStatus.SUCCESS;
            })
            .addCase(exportToEmail.rejected, (state) => {
                state.exportStatus = QueryStatus.FAILED;
            });
    }
});

export default exportSlice.reducer;
