export default function componentStyleOverrides(theme) {
    const bgColor = 'white';
    return {
        Main: {
            styleOverrides: {
                root: {
                    width: '100vw',
                    // minHeight: 'calc(100vh - 88px)',
                    height: `calc(100vh - 60px)`,
                    flexGrow: 1,
                    padding: '0px',
                    marginTop: '60px',
                    marginRight: '0px',
                    marginLeft: '0px',
                    borderRadius: '0px',
                    backgroundColor: bgColor
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                    margin: '0px',
                    height: '60px',
                    padding: '0px'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    margin: '0px'
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    margin: '0px',
                    padding: '0px',

                    '& .MuiListItem-root': {
                        // margin: '0px',
                        padding: '0px'
                    }
                }
            }
        },
        // MuiTreeView is from lab. It doesn't support customized style. It can only be overridden like this.
        MuiTreeView: {
            styleOverrides: {
                root: {
                    height: '100%',
                    overflowY: 'auto',
                    flexGrow: 1,

                    '& .MuiTreeItem-root': {
                        margin: '6px',
                        padding: '0px',

                        '& .MuiTreeItem-content': {
                            padding: '0px',

                            '&.Mui-selected': {
                                marginLeft: '3px'
                            },

                            '& .MuiTreeItem-label .MuiTypography-root': {
                                fontSize: '14px'
                            }
                        }
                    },

                    '& .MuiTreeItem-root.selectable > .MuiTreeItem-content > .MuiTreeItem-label > .MuiTypography-root': {
                        fontWeight: 'bold'
                    }
                }
            }
        },
        LogoBox: {
            styleOverrides: {
                root: {
                    marginLeft: '6px',
                    marginTop: '6px',
                    marginBottom: '6px',
                    height: '48px',
                    width: '200px'
                }
            }
        },
        HeaderButton: {
            styleOverrides: {
                root: {
                    margin: '0px',
                    width: '96px',
                    height: '52px',
                    padding: '15px 12px',
                    borderRadius: '7px',
                    textAlign: 'center',

                    // text
                    '& .MuiListItemText-root': {
                        '& .MuiTypography-root': {
                            fontSize: '20px'
                        }
                    },

                    '&:hover': {
                        backgroundColor: theme.palette.primary[400]
                    }
                }
            }
        },
        SearchBar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary[400],
                    borderRadius: '15px',
                    width: 500
                }
            }
        },
        MainCard: {
            styleOverrides: {
                root: {
                    margin: '8px',

                    paddingLeft: '16px',
                    paddingRight: '16px',

                    borderRadius: '16px',
                    border: '1px solid #ccc',
                    backgroundColor: theme.palette.background.paper,

                    // header
                    '& .MuiCardHeader-root': {
                        marginTop: '16px',
                        marginBottom: '16px',
                        padding: 0,

                        '& .MuiCardHeader-content > .MuiTypography-root': {
                            fontSize: '18px'
                        }
                    },

                    // divider
                    '& .MuiDivider-root': {
                        marginTop: '2px',
                        marginBottom: '10px'
                    },

                    // floating button
                    '& .MuiFab-root': {
                        width: '24px',
                        height: '24px',
                        minHeight: '0px',

                        top: '16px',
                        // right: '16px',
                        position: 'absolute',
                        zIndex: 15,

                        '& .MuiSvgIcon-root': {
                            width: '24px',
                            height: '24px'
                        },

                        '& .MuiTouchRipple-root': {
                            width: '24px',
                            height: '24px'
                        }
                    }
                }
            }
        },
        ToolboxCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '362px',
                        height: '100%'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '362px',
                        height: `calc(100vh - 454px)`
                    }

                    // scrollbar
                    // '& .scrollbar-container': {
                    //     height: `calc(100vh - 454px - 28px - 16 * 2px - 12px - 16px)`
                    // }
                }
            }
        },
        SubToolboxCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '362px',
                        height: '100%'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '362px',
                        height: `calc(100vh - 160px)`
                    },

                    // scrollbar
                    '& .scrollbar-container': {
                        height: `calc(100vh - 454px - 28px - 16 * 2px - 12px - 16px - 2 * 46px - 8px + 300px)`,
                        width: '362px'
                    }
                }
            }
        },
        QueryNodeCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '362px',
                        height: '100%'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '362px',
                        height: `calc(100vh - 160px - 220px)`
                    },

                    // scrollbar
                    '& .scrollbar-container': {
                        height: `calc(100vh - 160px - 28px - 16 * 2px - 12px - 16px - 220px)`,
                        width: '332px'
                    }
                }
            }
        },
        SubgraphCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '284px',
                        height: `calc(100vh - 16px)`
                    },
                    [theme.breakpoints.up('sm')]: {
                        // takes height: 378px, width: 504px
                        width: 'calc(100vw - 370px - 16px)',
                        height: `calc(100vh - 160px - 220px)`,
                        position: 'absolute',
                        // left: 'calc(362px + 8px)',
                        left: '0px',
                        top: '120px',
                        zIndex: 10
                    },

                    '& .force-graph-container': {
                        [theme.breakpoints.down('sm')]: {
                            width: '284px',
                            height: `calc(100vh - 84px)`
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '456px',
                            height: '306px'
                        }
                    }
                }
            }
        },
        ResultGraphCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '284px',
                        height: `calc(100vh - 16px)`
                    },
                    [theme.breakpoints.up('sm')]: {
                        // takes height: 378px, width: 504px
                        width: 'calc(100vw / 3 * 2)',
                        height: `calc(100vh - 80px)`,
                        position: 'absolute',
                        left: '0px',
                        top: '60px',
                        zIndex: 10
                    },

                    '& .force-graph-container': {
                        [theme.breakpoints.down('sm')]: {
                            width: '284px',
                            height: `calc(100vh - 84px)`
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '456px',
                            height: '306px'
                        }
                    }
                }
            }
        },
        ResultPropertyCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '284px',
                        height: `calc(100vh - 16px)`
                    },
                    [theme.breakpoints.up('sm')]: {
                        // takes height: 378px, width: 504px
                        width: 'calc(100vw / 3 - 16px - 8px)',
                        height: `calc(100vh - 80px)`,
                        position: 'absolute',
                        right: '0px',
                        top: '60px',
                        zIndex: 10
                    },

                    '& .force-graph-container': {
                        [theme.breakpoints.down('sm')]: {
                            width: '284px',
                            height: `calc(100vh - 84px)`
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '456px',
                            height: '306px'
                        }
                    }
                }
            }
        },
        LogCard: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '362px',
                        height: '100%'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: 'calc(100vw - 370px - 16px)',
                        height: `calc(220px - 8px)`,
                        position: 'absolute',
                        // left: 'calc(362px + 8px)',
                        left: '0px',
                        top: 'calc(120px + 100vh - 160px - 220px + 8px)',
                        zIndex: 10
                    },

                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    zIndex: 10,

                    // scrollbar: card-h - header(24+32) - divider(13)
                    '& .scrollbar-container': {
                        [theme.breakpoints.down('sm')]: {
                            height: `calc(100vh - 89px)`
                        },
                        [theme.breakpoints.only('sm')]: {
                            height: `calc(100vh - 69px)`
                        },
                        [theme.breakpoints.up('md')]: {
                            height: `calc(100vh - 158px)`
                        }
                    }
                }
            }
        },
        LogSubcard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    backgroundColor: theme.palette.primary[400],
                    paddingLeft: 3,
                    marginTop: 3
                }
            }
        },
        SubcardButton: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundColor: theme.palette.primary[50],
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    textAlign: 'center',
                    marginLeft: 10,
                    marginRight: 15
                }
            }
        },
        MenuButton: {
            styleOverrides: {
                root: {
                    // backgroundColor: theme.palette.background.paper,
                    // opacity: 0.5,
                    // marginBottom: '4px',
                    height: '42px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '16px',
                    paddingRight: '24px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '348px'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '348px'
                    },
                    borderRadius: '8px',
                    border: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.primary[400]
                    },
                    '& .MuiListItemText-root': {
                        [theme.breakpoints.down('sm')]: {
                            width: '100%'
                        },
                        [theme.breakpoints.only('sm')]: {
                            width: '308px'
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '308px'
                        },
                        height: '22px',
                        '& .MuiTypography-root': {
                            fontSize: '15px',
                            lineHeight: '22px'
                        }
                    },

                    // icon
                    '& > .MuiListItemIcon-root': {
                        margin: 'auto',
                        minWidth: '35px',
                        '& > .icon': {
                            width: '20px',
                            height: '20px',
                            strokeWidth: '1.5'
                        }
                    }
                }
            }
        },
        MenuHoverButton: {
            styleOverrides: {
                root: {
                    // backgroundColor: theme.palette.background.paper,
                    // opacity: 0.5,
                    marginBottom: '4px',
                    height: '42px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '16px',
                    paddingRight: '24px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%'
                    },
                    [theme.breakpoints.only('sm')]: {
                        width: '378px'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '378px'
                    },
                    borderRadius: '8px',
                    border: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.primary[400]
                    },
                    '& .MuiListItemText-root': {
                        [theme.breakpoints.down('sm')]: {
                            width: '100%'
                        },
                        [theme.breakpoints.only('sm')]: {
                            width: '338px'
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '338px'
                        },
                        height: '22px',
                        '& .MuiTypography-root': {
                            fontSize: '22px',
                            lineHeight: '22px'
                        }
                    },

                    // icon
                    '& > .MuiListItemIcon-root': {
                        margin: 'auto',
                        minWidth: '35px',
                        '& > .icon': {
                            width: '20px',
                            height: '20px',
                            strokeWidth: '1.5'
                        }
                    }
                }
            }
        },
        SubgraphButton: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    backgroundColor: '#ede7f6'
                }
            }
        },
        MenuList: {
            styleOverrides: {
                root: {
                    width: '100%',

                    // subheader
                    '& > .MuiTypography-root': {
                        marginTop: '4px',
                        marginBottom: '4px',

                        fontSize: '14px'
                    },

                    '& > .MuiDivider-root': {
                        marginTop: '2px',
                        marginBottom: '10px',

                        borderWidth: '1px'
                    }
                }
            }
        },
        ToolboxCardDisplayButton: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    top: '65px',
                    left: '10px',
                    zIndex: 10,
                    padding: '5px 10px',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.50))',
                    '&:hover': {
                        filter: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.30)',
                        backgroundColor: '#ffffff'
                    }
                }
            }
        },
        LogCardDisplayButton: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    bottom: '65px',
                    right: '10px',
                    zIndex: 10,
                    padding: '5px 10px',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.50))',
                    '&:hover': {
                        filter: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.30)',
                        backgroundColor: '#ffffff'
                    }
                }
            }
        },
        HeaderDisplayButton: {
            styleOverrides: {
                root: {
                    // position: 'fixed',
                    // top: '65px',
                    right: '10px',
                    zIndex: 10,
                    padding: '5px 10px',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.50))',
                    '&:hover': {
                        filter: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.30)',
                        backgroundColor: '#ffffff'
                    }
                }
            }
        },
        SubgraphCardDisplayButton: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    bottom: '65px',
                    left: '10px',
                    zIndex: 10,
                    padding: '5px 10px',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.50))',
                    '&:hover': {
                        filter: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.30)',
                        backgroundColor: '#ffffff'
                    }
                }
            }
        },
        ResultSwitchButton: {
            styleOverrides: {
                root: {
                    position: 'fixed',
                    top: '16%',
                    left: '60%',
                    zIndex: 10,
                    padding: '5px 10px',
                    borderRadius: '30px',
                    backgroundColor: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.30)',
                    '&:hover': {
                        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.50))'
                    }
                }
            }
        },
        SubCardContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    padding: '10px'
                }
            }
        },
        HomePageCard: {
            styleOverrides: {
                root: {
                    borderRadius: '32px',
                    padding: '32px',
                    maxWidth: '568px',
                    width: '30%',

                    '& .MuiCardHeader-root': {
                        padding: '0px',
                        height: '50px',
                        marginTop: '8px',
                        marginBottom: '8px',
                        '& .MuiCardHeader-content > .MuiTypography-root': {
                            fontSize: '50px',
                            height: '50px',
                            textAlign: 'center',
                            lineHeight: '50px'
                        }
                    },
                    '& .MuiCardContent-root': {
                        paddingTop: '16px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingBottom: '24px'
                    }
                }
            }
        },
        HomePageCardTitleBlock: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(63, 81, 108, 0.72)',
                    '& .MuiCardHeader-root': {
                        '& .MuiCardHeader-content > .MuiTypography-root': {
                            color: '#FFFFFF',
                            fontWeight: 'bold'
                        }
                    },
                    '& .MuiCardContent-root': {
                        textAlign: 'center',
                        '& > .MuiBox-root': {
                            '& > .MuiTypography-root': {
                                fontSize: '16px',
                                color: '#FFFFFF',
                                fontWeight: '100',
                                lineHeight: 1.5
                            },
                            '& > a:not(.MuiButton-root) > .MuiTypography-root': {
                                fontSize: '18px',
                                color: 'lightblue',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }
                        }
                    }
                }
            }
        },
        HomePageCardExampleBlock: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(63, 81, 108, 0.72)',
                    '& .MuiCardHeader-root': {
                        '& .MuiCardHeader-content > .MuiTypography-root': {
                            color: '#FFFFFF',
                            fontWeight: 'bold'
                        }
                    },
                    '& .MuiCardContent-root': {
                        textAlign: 'center',
                        '& > .MuiBox-root': {
                            '& > .MuiTypography-root': {
                                fontSize: '16px',
                                color: '#FFFFFF',
                                fontWeight: '100',
                                lineHeight: 1.5
                            },
                            '& > a:not(.MuiButton-root) > .MuiTypography-root': {
                                fontSize: '18px',
                                color: 'lightblue',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }
                        }
                    }
                }
            }
        },
        MainPageNavigationButton: {
            styleOverrides: {
                root: {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    height: '40px',
                    borderRadius: '20px',
                    textAlign: 'center',
                    backgroundColor: '#FFFFFF',
                    '& > .MuiTypography-root': {
                        fontSize: '20px',
                        color: theme.palette.primary['600']
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.primary['500'],
                        ' > .MuiTypography-root': {
                            color: theme.palette.primary['50']
                        }
                    }
                }
            }
        },
        GraphTitle: {
            styleOverrides: {
                root: {
                    width: '200px',
                    borderRadius: '16px',
                    zIndex: 11,
                    // '& .MuiCardHeader-content': {
                    //     alignItems: 'center'
                    // },
                    '& .MuiTypography-root': {
                        width: '150px',
                        color: 'black',
                        fontSize: '25px',
                        textAlign: 'center'
                    }
                }
            }
        },
        EdgeConfigCard: {
            styleOverrides: {
                root: {
                    width: '250px',
                    borderRadius: '16px',
                    // '& .MuiCardHeader-content': {
                    //     alignItems: 'center'
                    // },
                    '& .MuiTypography-root': {
                        color: 'black',
                        fontSize: '25px',
                        textAlign: 'center'
                    }
                }
            }
        },
        Legend: {
            styleOverrides: {
                root: {
                    width: '150px',
                    borderRadius: '16px',
                    // '& .MuiCardHeader-content': {
                    //     alignItems: 'center'
                    // },
                    '& .MuiTypography-root': {
                        color: 'black',
                        fontSize: '15px',
                        textAlign: 'center'
                    }
                }
            }
        },
        QueryNodePropertyConfigList: {
            styleOverrides: {
                root: {
                    '& .MuiListItem-root': {
                        marginTop: '16px',
                        marginBottom: '16px'
                    }
                }
            }
        }
        // ---- old component style -----
        // MuiPaper: {
        //     defaultProps: {
        //         elevation: 0
        //     },
        //     styleOverrides: {
        //         root: {
        //             backgroundImage: 'none'
        //         },
        //         rounded: {
        //             // borderRadius: `${theme?.customization?.borderRadius}px`
        //         }
        //     }
        // },
        // MuiCardHeader: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.text.primary,
        //             padding: '24px'
        //         },
        //         title: {
        //             fontSize: '1.125rem'
        //         }
        //     }
        // },
        // MuiCardContent: {
        //     styleOverrides: {
        //         root: {
        //             padding: '24px'
        //         }
        //     }
        // },
        // MuiCardActions: {
        //     styleOverrides: {
        //         root: {
        //             padding: '24px'
        //         }
        //     }
        // },
        // MuiListItemButton: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.primary.main,
        //             paddingTop: '5px',
        //             paddingBottom: '5px',
        //             backgroundColor: theme.palette.primary.main,
        //             '&.Mui-selected': {
        //                 color: theme.palette.secondary.dark,
        //                 backgroundColor: theme.palette.secondary.main,
        //                 '&:hover': {
        //                     backgroundColor: theme.palette.secondary.main
        //                 },
        //                 '& .MuiListItemIcon-root': {
        //                     color: theme.palette.secondary.dark
        //                 }
        //             },
        //             '&:hover': {
        //                 backgroundColor: theme.palette.secondary.light,
        //                 color: theme.palette.secondary.dark,
        //                 '& .MuiListItemIcon-root': {
        //                     color: theme.palette.secondary.main
        //                 }
        //             }
        //         },
        //         button: {
        //             backgroundColor: theme.palette.background.paper
        //         }
        //     }
        // },
        // MuiListItemIcon: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.text.dark,
        //             minWidth: '36px'
        //         }
        //     }
        // },
        // MuiListItemText: {
        //     styleOverrides: {
        //         primary: {
        //             color: theme.textDark
        //         }
        //     }
        // },
        // MuiInputBase: {
        //     styleOverrides: {
        //         input: {
        //             color: theme.textDark,
        //             '&::placeholder': {
        //                 color: theme.darkTextSecondary,
        //                 fontSize: '0.875rem'
        //             }
        //         }
        //     }
        // },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             background: bgColor,
        //             borderRadius: `${theme?.customization?.borderRadius}px`,
        //             '& .MuiOutlinedInput-notchedOutline': {
        //                 borderColor: theme.colors?.grey400
        //             },
        //             '&:hover $notchedOutline': {
        //                 borderColor: theme.colors?.primaryLight
        //             },
        //             '&.MuiInputBase-multiline': {
        //                 padding: 1
        //             }
        //         },
        //         input: {
        //             fontWeight: 500,
        //             background: bgColor,
        //             padding: '15.5px 14px',
        //             borderRadius: `${theme?.customization?.borderRadius}px`,
        //             '&.MuiInputBase-inputSizeSmall': {
        //                 padding: '10px 14px',
        //                 '&.MuiInputBase-inputAdornedStart': {
        //                     paddingLeft: 0
        //                 }
        //             }
        //         },
        //         inputAdornedStart: {
        //             paddingLeft: 4
        //         },
        //         notchedOutline: {
        //             borderRadius: `${theme?.customization?.borderRadius}px`
        //         }
        //     }
        // },
        // MuiSlider: {
        //     styleOverrides: {
        //         root: {
        //             '&.Mui-disabled': {
        //                 color: theme.colors?.grey300
        //             }
        //         },
        //         mark: {
        //             backgroundColor: theme.paper,
        //             width: '4px'
        //         },
        //         valueLabel: {
        //             color: theme?.colors?.primaryLight
        //         }
        //     }
        // },
        // MuiDivider: {
        //     styleOverrides: {
        //         root: {
        //             borderColor: theme.divider,
        //             opacity: 1
        //         }
        //     }
        // },
        // MuiAvatar: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.colors?.primaryDark,
        //             background: theme.colors?.primary200
        //         }
        //     }
        // },
        // MuiChip: {
        //     styleOverrides: {
        //         root: {
        //             '&.MuiChip-deletable .MuiChip-deleteIcon': {
        //                 color: 'inherit'
        //             }
        //         }
        //     }
        // },
        // MuiTooltip: {
        //     styleOverrides: {
        //         tooltip: {
        //             color: theme.paper,
        //             background: theme.colors?.grey700
        //         }
        //     }
        // },
        // MuiGrid: {
        //     styleOverrides: {
        //         root: {
        //             maxWidth: '100%'
        //         }
        //     }
        // }
    };
}
