const themeMixins = () => {
    return {
        toolbar: {
            minHeight: '48px',
            padding: '0px',
            '@media (min-width: 600px)': {
                minHeight: '48px',
                padding: '0px'
            }
        }
    };
};

export default themeMixins;
