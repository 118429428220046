import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconSearch } from '@tabler/icons';
import IconButton from '@mui/material/IconButton';
import { Button, Typography } from '@mui/material';

export default function CustomIconButtons({ sx, Icon, Text, onClick, TextLeft = true }) {
    //Can use Button, Fab, IconButton
    if (TextLeft) {
        return (
            <Button type="button" sx={sx} aria-label={Text} onClick={onClick}>
                <Typography variant="h2" sx={{ mr: '10px' }}>
                    {Text}
                </Typography>
                <Icon size="1.5rem" />
            </Button>
        );
    } else {
        return (
            <Button type="button" sx={sx} aria-label={Text} onClick={onClick}>
                <Icon size="1.5rem" />
                <Typography variant="h2" sx={{ ml: '10px' }}>
                    {Text}
                </Typography>
            </Button>
        );
    }
}
