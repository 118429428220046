import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './uiSlice';
import queryReducer from './querySlice';
import logReducer from './logSlice';
import pastReducer from './pastSlice';
import futureReducer from './futureSlice';
import { apiSlice } from './apiSlice';
import resultReducer from './resultSlice';
import exportReducer from './exportSlice';
import globalStorageReducer from './globalStorageSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/es/stateReconciler/hardSet'; // defaults to localStorage for web

// ==============================|| REDUX - MAIN STORE ||============================== //

const globalStorageConfig = {
    key: 'globalStorage',
    storage,
    stateReconciler: hardSet
};

const store = configureStore({
    reducer: {
        ui: uiReducer,
        query: queryReducer,
        log: logReducer,
        past: pastReducer,
        future: futureReducer,
        result: resultReducer,
        export: exportReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        globalStorage: persistReducer(globalStorageConfig, globalStorageReducer)
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
});

let persistor = persistStore(store);

export { store, persistor };
