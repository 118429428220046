import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { IconSearch } from '@tabler/icons';
import { styled } from '@mui/material/styles';

const ThemedSearchBar = styled(Paper, {
    name: 'SearchBar',
    overridesResolver: (props, styles) => {
        return [styles.root];
    }
})({});

export default function SearchBar() {
    return (
        <ThemedSearchBar component="form" sx={{ display: 'flex', alignItems: 'center', mr: 9 }}>
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <MenuIcon />
            </IconButton>
            <InputBase
                sx={{ flex: 1, backgroundColor: '#ffffff', borderRadius: '5px', pl: 2, ml: 1 }}
                placeholder="Search GKB"
                inputProps={{ 'aria-label': 'search GKB' }}
                variant={'h5'}
                color="inherit"
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <IconSearch stroke={1.5} size="1.3rem" />
            </IconButton>
        </ThemedSearchBar>
    );
}
