import { ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const HeaderButtonRoot = styled(ListItemButton, {
    name: 'HeaderButton',
    overridesResolver: (props, styles) => {
        return [styles.root];
    }
})({});

const HeaderButton = ({ text = 'TestToolboxButton', Icon, onClick, sx, disabled = false, href, mx = 0 }) => {
    const itemIconComponent = <Icon stroke={1.5} size="1.3rem" />;
    return (
        <HeaderButtonRoot onClick={onClick} component={Link} to={href}>
            <ListItemText>{text}</ListItemText>
        </HeaderButtonRoot>
    );
};

export default HeaderButton;
