import { createSlice } from '@reduxjs/toolkit';
import { SelectedItemTypes, TaskTypes } from '../../assets/constant';

// ==============================|| UI SLICE ||============================== //

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        selected: {
            selectedType: SelectedItemTypes.QUERY_BACKGROUND,
            selectedObject: null
        },
        task: {
            type: TaskTypes.NO_TASK,
            info: null,
            isValid: true
        },
        layout: {
            headerDisplaying: true,
            toolboxDisplaying: true,
            subgraphDisplaying: true,
            queryNodeSubGraphDisplaying: false,
            subgraphEnlarge: false,
            logDisplaying: true,
            queryFocusing: false
        }
    },
    reducers: {
        setSelectedType: (state, action) => {
            const type = action.payload;
            if (type !== state.selected.selectedType) {
                state.selected.selectedType = type;
            }
        },
        setSelectedObject: (state, action) => {
            const selectedObject = action.payload;
            if (selectedObject !== state.selected.selectedObject) {
                state.selected.selectedObject = selectedObject;
            }
        },
        clearSelected: (state) => {
            const { selectedType: preSelectedType, selectedObject: preSelectedObject } = state.selected;
            if (preSelectedType === SelectedItemTypes.QUERY_NODE || preSelectedType === SelectedItemTypes.QUERY_EDGE) {
                state.selected = { selectedType: SelectedItemTypes.QUERY_BACKGROUND, selectedObject: null };
            } else if (preSelectedType === SelectedItemTypes.RESULT_NODE || preSelectedType === SelectedItemTypes.RESULT_EDGE) {
                state.selected = { selectedType: SelectedItemTypes.RESULT_BACKGROUND, selectedObject: null };
            }
        },
        setTaskType: (state, action) => {
            state.task.type = action.payload;
        },
        setTaskInfo: (state, action) => {
            state.task.info = action.payload;
        },
        setTaskAsValid: (state) => {
            if (!state.task.isValid) {
                state.task.isValid = true;
            }
        },
        setTaskAsInvalid: (state) => {
            if (state.task.isValid) {
                state.task.isValid = false;
            }
        },
        clearTask: (state) => {
            const { type: preType, info: preInfo } = state.task;
            if (preType !== TaskTypes.NO_TASK || preInfo !== null) {
                state.task = { type: TaskTypes.NO_TASK, info: null, isValid: true };
            }
        },
        hideHeader: (state) => {
            state.layout.headerDisplaying = false;
        },
        displayHeader: (state) => {
            state.layout.headerDisplaying = true;
        },
        hideToolbox: (state) => {
            state.layout.toolboxDisplaying = false;
        },
        displayToolbox: (state) => {
            state.layout.toolboxDisplaying = true;
        },
        hideLog: (state) => {
            state.layout.logDisplaying = false;
        },
        displayLog: (state) => {
            state.layout.logDisplaying = true;
        },
        hideSubgraph: (state) => {
            state.layout.subgraphDisplaying = false;
        },
        displaySubgraph: (state) => {
            state.layout.subgraphDisplaying = true;
        },
        hideQueryNodeSubgraph: (state) => {
            state.layout.queryNodeSubGraphDisplaying = false;
        },
        displayQueryNodeSubgraph: (state) => {
            state.layout.queryNodeSubGraphDisplaying = true;
        },
        disableEnlargeSubgraph: (state) => {
            state.layout.subgraphEnlarge = false;
        },
        enlargeSubgraph: (state) => {
            state.layout.subgraphEnlarge = true;
        },
        focusOnQuery: (state) => {
            state.layout.queryFocusing = true;
        },
        focusOnResult: (state) => {
            state.layout.queryFocusing = false;
        }
    }
});

export const {
    setSelectedType,
    setSelectedObject,
    clearSelected,
    setTaskInfo,
    setTaskAsValid,
    setTaskAsInvalid,
    clearTask,
    setTaskType,
    hideHeader,
    displayHeader,
    hideLog,
    displayLog,
    hideSubgraph,
    displaySubgraph,
    hideQueryNodeSubgraph,
    displayQueryNodeSubgraph,
    disableEnlargeSubgraph,
    enlargeSubgraph,
    hideToolbox,
    displayToolbox,
    focusOnQuery,
    focusOnResult
} = uiSlice.actions;

export default uiSlice.reducer;
