import colors from './paletteColor.scss';

export default function themePalette() {
    return {
        primary: {
            main: colors.primaryMain,
            50: colors.primary50,
            100: colors.primary100,
            200: colors.primary200,
            300: colors.primary300,
            400: colors.primary400,
            500: colors.primary500,
            600: colors.primary600,
            700: colors.primary700,
            800: colors.primary800
        }
        // common: {
        //     // black: colors.darkPaper
        // },
        // primary: {
        //     light: colors.primaryLight,
        //     main: colors.primaryMain,
        //     dark: colors.primaryDark,
        //     200: colors.primary200,
        //     800: colors.primary800
        // },
        // secondary: {
        //     light: colors.secondaryLight,
        //     main: colors.secondaryMain,
        //     dark: colors.secondaryDark,
        //     200: colors.secondary200,
        //     800: colors.secondary800
        // },
        // error: {
        //     light: colors.errorLight,
        //     main: colors.errorMain,
        //     dark: colors.errorDark
        // },
        // orange: {
        //     light: colors.orangeLight,
        //     main: colors.orangeMain,
        //     dark: colors.orangeDark
        // },
        // warning: {
        //     light: colors.warningLight,
        //     main: colors.warningMain,
        //     dark: colors.warningDark
        // },
        // success: {
        //     light: colors.successLight,
        //     200: colors.success200,
        //     main: colors.successMain,
        //     dark: colors.successDark
        // },
        // grey: {
        //     50: colors.grey50,
        //     100: colors.grey100,
        //     500: colors.grey500,
        //     600: colors.grey600,
        //     700: colors.grey700,
        //     900: colors.grey900
        // },
        // // dark: {
        // //     light: colors.darkPrimaryLight,
        // //     main: colors.darkPrimaryMain,
        // //     dark: colors.darkPrimaryDark,
        // //     800: colors.darkPrimary800,
        // //     900: colors.darkPrimary900
        // // },
        // text: {
        //     primary: colors.darkTextPrimary,
        //     secondary: colors.darkTextSecondary
        //     // dark: theme.textDark,
        //     // hint: colors.grey100
        // },
        // background: {
        //     paper: colors.paper,
        //     default: colors.backgroundDefault
        // }
    };
}
