import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

export default function FloatingActionButtons({ sx, onClick, display = true, enlarge = false, useEnlargeIcon = false }) {
    return (
        <Fab color="inherit" aria-label="add" size="small" sx={sx} onClick={onClick}>
            {!display && !useEnlargeIcon && <AddIcon />}
            {display && !useEnlargeIcon && <RemoveIcon />}
            {enlarge && useEnlargeIcon && <CloseFullscreenIcon />}
            {!enlarge && useEnlargeIcon && <OpenInFullIcon />}
        </Fab>
    );
}
