import { createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit';

const logAdapter = createEntityAdapter({
    sortComparer: (log1, log2) => log1.createTime.localeCompare(log2.createTime)
});

const logInitialState = logAdapter.getInitialState({});

export const logSlice = createSlice({
    name: 'log',
    initialState: logInitialState,
    reducers: {
        addLog: {
            reducer(state, action) {
                const logObj = action.payload;
                logAdapter.addOne(state, logObj);
            },
            prepare(logObj) {
                return {
                    payload: {
                        createTime: new Date().toISOString(),
                        id: nanoid(),
                        ...logObj
                    }
                };
            }
        },
        deleteLogById: (state, action) => {
            const id = action.payload;
            logAdapter.removeOne(state, id);
        }
    }
});

export const { addLog, deleteLogById } = logSlice.actions;

export const { selectAll: selectAllLogs, selectById: selectLogById } = logAdapter.getSelectors((state) => state.log);

export default logSlice.reducer;
