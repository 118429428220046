import { ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeaderButtonRoot = styled(ListItemButton, {
    name: 'HeaderButton',
    overridesResolver: (props, styles) => {
        return [styles.root];
    }
})({});

const HeaderExternalButton = ({ text, Icon, href }) => {
    const itemIconComponent = <Icon stroke={1.5} size="1.3rem" />;
    return (
        <HeaderButtonRoot href={href} target={'_blank'}>
            <ListItemText>{text}</ListItemText>
        </HeaderButtonRoot>
    );
};

export default HeaderExternalButton;
