import { createAsyncThunk, createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit';
import axios from 'axios';
import { QueryStatus } from '../../assets/constant';
import { flaskBackendAxiosInstance } from '../../axios';

export const transformGraphicalToCypher = createAsyncThunk('/transform/graphical/cypher', async (graphObj) => {
    return await flaskBackendAxiosInstance
        .post('/transform/graphical/cypher', graphObj, {
            mode: 'cors'
        })
        .then((response) => response.data);
});

const queryNodeAdapter = createEntityAdapter();

const queryNodeInitialState = queryNodeAdapter.getInitialState({});

const queryEdgeAdapter = createEntityAdapter();

const queryEdgeInitialState = queryEdgeAdapter.getInitialState({});

export const querySlice = createSlice({
    name: 'query',
    initialState: {
        cypherQuery: '',
        nodes: queryNodeInitialState,
        links: queryEdgeInitialState,
        queryTransformStatus: QueryStatus.IDLE // This is auto updated
    },
    reducers: {
        addOrUpdateNode: {
            reducer(state, action) {
                const newNodeObject = action.payload;
                // pushOrReplace(newNodeObject, state.nodes);
                queryNodeAdapter.setOne(state.nodes, newNodeObject);
            },
            prepare(nodeObj) {
                const outputObj = { ...nodeObj };
                if (outputObj.id == undefined) {
                    outputObj.id = nanoid();
                }
                return {
                    payload: outputObj
                };
            }
        },
        deleteNodeById: (state, action) => {
            const nodeId = action.payload;
            queryNodeAdapter.removeOne(state.nodes, nodeId);
        },
        addOrUpdateLink: {
            reducer(state, action) {
                const newLinkObject = action.payload;
                queryEdgeAdapter.setOne(state.links, newLinkObject);
            },
            prepare(linkObj) {
                const outputObj = { ...linkObj };
                if (outputObj.id == undefined) {
                    outputObj.id = nanoid();
                }
                return {
                    payload: outputObj
                };
            }
        },
        deleteLinkById: (state, action) => {
            const linkId = action.payload;
            queryEdgeAdapter.removeOne(state.links, linkId);
        },
        setQueryNodeList: (state, action) => {
            queryNodeAdapter.setAll(state.nodes, action.payload);
        },
        setQueryLinkList: (state, action) => {
            queryEdgeAdapter.setAll(state.links, action.payload);
        },
        clearQueryNodeList: (state) => {
            queryNodeAdapter.removeAll(state.nodes);
        },
        clearQueryLinkList: (state) => {
            queryEdgeAdapter.removeAll(state.links);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(transformGraphicalToCypher.pending, (state, action) => {
                state.queryTransformStatus = QueryStatus.LOADING;
            })
            .addCase(transformGraphicalToCypher.fulfilled, (state, action) => {
                // console.log('query with graph fulfilled');
                const fetchedQuery = action.payload;
                state.cypherQuery = fetchedQuery;
                state.queryTransformStatus = QueryStatus.SUCCESS;
            })
            .addCase(transformGraphicalToCypher.rejected, (state, action) => {
                console.log('query with graph rejected -> ', action.error.message);
                state.queryTransformStatus = QueryStatus.FAILED;
            });
    }
});

export const {
    addOrUpdateNode,
    deleteNodeById,
    addOrUpdateLink,
    deleteLinkById,
    setQueryNodeList,
    setQueryLinkList,
    clearQueryNodeList,
    clearQueryLinkList
} = querySlice.actions;

export default querySlice.reducer;

export const { selectAll: selectAllQueryNodes, selectById: selectQueryNodeById } = queryNodeAdapter.getSelectors(
    (state) => state.query.nodes
);

export const { selectAll: selectAllQueryEdges, selectById: selectQueryEdgeById } = queryEdgeAdapter.getSelectors(
    (state) => state.query.links
);
