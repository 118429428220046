import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
// import { styled } from '@mui/system';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from './LogoSection';
import NavSection from './NavSection';
import SearchBar from '../../../ui-component/SearchBar/SearchBar';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const LogoBox = styled(Box, {
        name: 'LogoBox',
        slot: 'Root',
        overridesResolver: (props, styles) => {
            return [styles.root];
        }
    })({});

    return (
        <>
            <LogoBox>
                <LogoSection />
            </LogoBox>
            <Box sx={{ flexGrow: 1 }} />
            {/*<SearchBar />*/}
            <NavSection />
        </>
    );
};

export default Header;
