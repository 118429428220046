import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// main page routing
const MainPage = Loadable(lazy(() => import('views/main')));

// query page routing
const QueryPage = Loadable(lazy(() => import('views/query')));

// result page routing
const ResultPage = Loadable(lazy(() => import('views/result')));

// tutorial page routing
const TutorialPage = Loadable(lazy(() => import('views/tutorial')));

// contact page routing
const ContactPage = Loadable(lazy(() => import('views/contact')));

// docs page routing
const DocsPage = Loadable(lazy(() => import('views/docs')));

// updates page routing
const UpdatesPage = Loadable(lazy(() => import('views/updates')));

// test page routing
// const TestPage = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <MainPage />
        },
        {
            path: 'query',
            element: <QueryPage />
        },
        {
            path: 'result',
            element: <ResultPage />
        },
        {
            path: 'tutorial',
            element: <TutorialPage />
        },
        {
            path: 'contact',
            element: <ContactPage />
        },
        {
            path: 'documentation',
            element: <DocsPage />
        },
        {
            path: 'changelog',
            element: <UpdatesPage />
        }
    ]
};

export default MainRoutes;
