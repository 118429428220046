import PropTypes from 'prop-types';
import { Divider, List, Typography } from '@mui/material';
import HeaderButtonNavItem from '../HeaderButtonNavItem';

// ==============================|| MENU BUTTON NAV GROUP ||============================== //

const HeaderButtonNavGroup = ({ item }) => {
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'item':
                return <HeaderButtonNavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List sx={{ display: 'flex' }}>{items}</List>
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

HeaderButtonNavGroup.propTypes = {
    item: PropTypes.object
};

export default HeaderButtonNavGroup;
