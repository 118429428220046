import { useDispatch, useSelector } from 'react-redux';
import FloatingActionButtons from '../../../ui-component/FloatButton';
import { hideHeader, hideSubgraph } from '../../../store/uiSlice';
import { useTheme } from '@mui/material/styles';
import CustomIconButtons from '../../../ui-component/CustomIconButton';
import { IconMenu } from '@tabler/icons';

const HeaderDisplayButton = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const headerDisplaying = useSelector((state) => state.ui.layout.headerDisplaying);
    return headerDisplaying ? null : (
        <CustomIconButtons
            sx={theme.components.HeaderDisplayButton.styleOverrides.root}
            Icon={IconMenu}
            Text={'Header'}
            onClick={() => {
                dispatch(hideHeader());
            }}
            TextLeft={false}
        />
    );
};

export default HeaderDisplayButton;
