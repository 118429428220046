import { createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit';

const futureAdapter = createEntityAdapter({
    sortComparer: (s1, s2) => s1.createTime.localeCompare(s2.createTime)
});

const futureInitialState = futureAdapter.getInitialState({});

export const FutureSlice = createSlice({
    name: 'future',
    initialState: futureInitialState,
    reducers: {
        addFuture: {
            reducer(state, action) {
                const futureObj = action.payload;
                futureAdapter.addOne(state, futureObj);
            },
            prepare(futureObj) {
                return {
                    payload: {
                        createTime: new Date().toISOString(),
                        id: nanoid(),
                        ...futureObj
                    }
                };
            }
        },
        deleteFutureById: (state, action) => {
            const id = action.payload;
            futureAdapter.removeOne(state, id);
        },
        clearFuture: (state, action) => {
            futureAdapter.removeAll(state);
        }
    }
});

export const { addFuture, deleteFutureById, clearFuture } = FutureSlice.actions;
export const {
    selectIds: selectAllFutureIds,
    selectAll: selectAllFuture,
    selectById: selectFutureById
} = futureAdapter.getSelectors((state) => state.future);

export default FutureSlice.reducer;
