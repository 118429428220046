import PropTypes from 'prop-types';

// material-ui
import HeaderButtonNavGroup from './HeaderButtonNavGroup';

// ==============================|| HEADER BUTTON LIST ||============================== //

const HeaderButtonList = ({ itemObject }) => {
    // console.log('itemObject -> ', itemObject);
    const navItems = itemObject.items.map((item) => {
        return <HeaderButtonNavGroup key={item.id} item={item} />;
    });

    return <>{navItems}</>;
};

HeaderButtonList.propTypes = {
    itemObject: PropTypes.object
};

export default HeaderButtonList;
