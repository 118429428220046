import { createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit';

const pastAdapter = createEntityAdapter({
    sortComparer: (s1, s2) => s1.createTime.localeCompare(s2.createTime)
});

const pastInitialState = pastAdapter.getInitialState({});

export const PastSlice = createSlice({
    name: 'past',
    initialState: pastInitialState,
    reducers: {
        addPast: {
            reducer(state, action) {
                const pastObj = action.payload;
                pastAdapter.addOne(state, pastObj);
            },
            prepare(pastObj) {
                return {
                    payload: {
                        createTime: new Date().toISOString(),
                        id: nanoid(),
                        ...pastObj
                    }
                };
            }
        },
        deletePastById: (state, action) => {
            const id = action.payload;
            pastAdapter.removeOne(state, id);
        }
    }
});

export const { addPast, deletePastById } = PastSlice.actions;
export const {
    selectIds: selectAllPastIds,
    selectAll: selectAllPast,
    selectById: selectPastById
} = pastAdapter.getSelectors((state) => state.past);

export default PastSlice.reducer;
