import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';

// ==============================|| MENU BUTTON NAV ITEMS ||============================== //

const HeaderButtonNavItem = ({ item }) => {
    return <ListItem>{item.component}</ListItem>;
};

HeaderButtonNavItem.propTypes = {
    item: PropTypes.object
};

export default HeaderButtonNavItem;
