import headerMenuItems from './header-menu-items';
import HeaderButtonList from '../../../../ui-component/HeaderButtonList';

// ==============================|| NOTIFICATION ||============================== //

const NavSection = () => {
    return <HeaderButtonList itemObject={headerMenuItems} />;
    // return <div />;
};

export default NavSection;
