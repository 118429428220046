import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const globalStorageSlice = createSlice({
    name: 'globalStorage',
    initialState: {
        currResult: { nodes: '', edges: '' },
        // currResultNodes: '',
        // currResultEdges: ''
        currResultAssociatedQuery: { nodes: '', edges: '' }
    },
    reducers: {
        setGlobalStorageCurrResultNodeList: (state, action) => {
            // currResultNodeAdapter.setAll(state.currResult.nodes, action.payload);
            state.currResult.nodes = action.payload;
            // console.log('current saved nodes', state.currResultNodes);
        },
        setGlobalStorageCurrResultEdgeList: (state, action) => {
            state.currResult.edges = action.payload;
            // currResultEdgeAdapter.setAll(state.currResult.edges, action.payload);
        },
        setGlobalStorageAssociatedQueryNodeList: (state, action) => {
            // currResultAssociatedQueryNodeAdapter.setAll(state.currResultAssociatedQuery.nodes, action.payload);
            state.currResultAssociatedQuery.nodes = action.payload;
        },
        setGlobalStorageAssociatedQueryEdgeList: (state, action) => {
            // currResultAssociatedQueryEdgeAdapter.setAll(state.currResultAssociatedQuery.edges, action.payload);
            state.currResultAssociatedQuery.edges = action.payload;
        },
        clearGlobalStorageCurrentSearchResultAndAssociatedQuery: (state, action) => {
            state.currResult = { nodes: '', edges: '' };
            state.currResultAssociatedQuery = { nodes: '', edges: '' };
            // currResultNodeAdapter.removeAll(state.currResult.nodes);
            // currResultEdgeAdapter.removeAll(state.currResult.edges);
            // currResultAssociatedQueryNodeAdapter.removeAll(state.currResultAssociatedQuery.nodes);
            // currResultAssociatedQueryEdgeAdapter.removeAll(state.currResultAssociatedQuery.edges);
        }
    }
});

export const {
    setGlobalStorageCurrResultNodeList,
    setGlobalStorageCurrResultEdgeList,
    setGlobalStorageAssociatedQueryNodeList,
    setGlobalStorageAssociatedQueryEdgeList,
    clearGlobalStorageCurrentSearchResultAndAssociatedQuery
} = globalStorageSlice.actions;

export default globalStorageSlice.reducer;
