import { IconAddressBook, IconArticle, IconHelp, IconSearch, IconNews } from '@tabler/icons';
import HeaderButton from '../../../../../../ui-component/HeaderButtonList/HeaderButton';
import HeaderExternalButton from '../../../../../../ui-component/HeaderButtonList/HeaderExtrenalButton';

const headerNavButtons = {
    id: 'header-nav-buttons',
    type: 'group',
    children: [
        {
            id: 'query',
            type: 'item',
            component: <HeaderButton text={'Query'} Icon={IconSearch} href={'/query'} />
        },
        {
            id: 'tutorial',
            type: 'item',
            component: <HeaderExternalButton text={'Tutorial'} Icon={IconHelp} href={'https://www.youtube.com/watch?v=Cnl9RpUDpkQ'} />
        },
        {
            id: 'docs',
            type: 'item',
            component: (
                <HeaderExternalButton
                    text={'Docs'}
                    Icon={IconArticle}
                    href={'https://liu-bioinfo-lab.github.io/GenomicKB_documentation/'}
                />
            )
        },
        {
            id: 'contact',
            type: 'item',
            component: <HeaderButton text={'Contact'} Icon={IconAddressBook} href={'/contact'} />
        },
        // {
        //     id: 'updates',
        //     type: 'item',
        //     component: <HeaderButton text={'Updates'} Icon={IconNews} href={'/changelog'} />
        // },
        {
            id: 'whats_new',
            type: 'item',
            component: (
                <HeaderExternalButton
                    text={"What's New"}
                    Icon={IconArticle}
                    href={'https://liu-bioinfo-lab.github.io/GenomicKB_documentation/updates/index.html'}
                />
            )
        },
        {
            id: 'data_dump',
            type: 'item',
            component: (
                <HeaderExternalButton
                    text={'Data Dump'}
                    Icon={IconArticle}
                    href={'https://liu-bioinfo-lab.github.io/GenomicKB_documentation/documentation/db_download/index.html'}
                />
            )
        }
    ]
};

export default headerNavButtons;
