// theme constant
export const gridSpacing = 1;

// users' selected types
export const SelectedItemTypes = {
    QUERY_BACKGROUND: 'QUERY_BACKGROUND',
    QUERY_NODE: 'QUERY_NODE',
    QUERY_EDGE: 'QUERY_EDGE',
    RESULT_BACKGROUND: 'RESULT_BACKGROUND',
    RESULT_EDGE: 'RESULT_EDGE',
    RESULT_NODE: 'RESULT_NODE'
};

// task types
export const TaskTypes = {
    READ_ONLY: 'READ_ONLY',
    NO_TASK: 'NO_TASK',
    CONFIG_NODE: 'CONFIG_NODE',
    CONFIG_NODE_TYPE: 'CONFIG_NODE_TYPE',
    CONFIG_EDGE: 'CONFIG_EDGE',
    CONFIG_EDGE_TYPE: 'CONFIG_EDGE_TYPE',
    SELECT_SOURCE_NODE: 'SELECT_SOURCE_NODE',
    SELECT_TARGET_NODE: 'SELECT_TARGET_NODE'
};

export const titles = {
    QUERY_TOOLBOX_TITLE: 'Query Toolbox',
    QUERY_NODE_TITLE: 'Query Node Toolbox',
    QUERY_EDGE_TITLE: 'Query Edge Toolbox',
    RESULT_INFO_TITLE: 'Result Info',
    RESULT_NODE_TITLE: 'Result Node Info',
    RESULT_EDGE_TITLE: 'Result Edge Info',
    NO_MATCH: 'No Match!'
};

export const QueryStatus = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
};

// TODO: use enum to define all this parameters.
