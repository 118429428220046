import { createTheme } from '@mui/material/styles';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import themeMixins from './mixins';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = () => {
    const appTheme = createTheme({
        direction: 'ltr',
        mixins: themeMixins(),
        palette: themePalette(),
        typography: themeTypography()
    });
    appTheme.components = componentStyleOverrides(appTheme); // the component style depends on palette and typography
    return appTheme;
};

export default theme;
